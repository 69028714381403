










































/* eslint-disable */

// IMPORTS
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DataLoader from './SlackDataLoader.vue'
import Header from './SlackMessagesHeader.vue'
import VueMarkdown from 'vue-markdown'

export default Vue.extend({
  name: 'SlackMessages',

  // COMPONENTS
  components: {
    DataLoader,
    Header,
    VueMarkdown
  },

  // COMPUTED
  computed: {
    ...mapGetters([
      'getSlackMessages',
      'getSlackLoader'
    ]),
    ...mapActions([
      'fetchSlackMessages'
    ])
  },

  // CREATED
  created () {
    this.$store.dispatch('fetchSlackMessages', this.$store.state.synergyChannelId)

    if (this.getSlackLoader.loaderCount === 0) {
      this.$store.state.slackLoader.loaderToggle = true
    }

    // const emoji = new EmojiConvertor()
    // console.log(emoji.replace_colons('Hello :thumbsup:'))
  }
})

