



































// IMPORTS
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'AzureDataLoader',

  // DATA
  data: () => ({
    oopsDiagram: false
  }),

  // COMPUTED
  computed: {
    ...mapGetters([
      'getAzureLoader',
      'getAzureData'
    ])
  },

  // METHODS
  methods: {
    CheckIfDataIsLoaded () {
      if (Object.keys(this.getAzureData).length === 0) {
        this.getAzureLoader.loaderToggle = false
        this.oopsDiagram = true
        // console.log('An error occured while loading Azure data')
      }
    },
    TryAgain () {
      this.getAzureLoader.loaderCount = 0
      this.$store.state.azureRefreshToggle = !this.$store.state.azureRefreshToggle
    }
  },

  // MOUNTED
  mounted () {
    if (this.getAzureLoader.loaderToggle === true) {
      setTimeout(() => {
        this.CheckIfDataIsLoaded()
      }, 6000)
    }
  }

})
