

















































































/* eslint-disable */

  // IMPORTS
  import Vue from 'vue'
  import { mapGetters} from 'vuex'

  export default Vue.extend({
    name: 'AzureFailedBackups',

    // COMPUTED
    computed: {
      ...mapGetters([
        'getAzureData'
      ])
    }

  })

  