


















// IMPORTS
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Pagetitle',

  // COMPUTED
  computed: {
    ...mapGetters([
      'getPageTitle'
    ])
  }

})

