<!-- HTML -->
<template>
  <div id="homeazure">

    <!-- TITLE -->
    <v-row class="mb-1" no-gutters>
      <v-col cols="12">
        <v-card tile>
          <h1 v-if="!getShowAzureData" class="text-center font-weight-medium">Azure</h1>
          <h1 v-if="getShowAzureData" class="text-center font-weight-medium" :class="getAzureData.backupCount.failed == 0 && getAzureData.appCount.failed == 0 ? 'green lighten-4' : 'red lighten-3'">Azure</h1>
        </v-card>
      </v-col>
    </v-row>

    <!-- NUMBERS -->
    <v-card tile flat class="pb-3 mb-4">
      <v-card flat loading="orange lighten-1">
        <v-card-text v-if="!getShowAzureData">
          Resource group ophalen
        </v-card-text>
      </v-card>
      <v-card-text v-if="getShowAzureData">

        <v-row>
          <v-col cols="10 offset-1">
            <v-card tile flat :class="getAzureData.backupCount.failed == 0 ? 'green lighten-4' : 'red lighten-3'">
              <v-card-text>
              <h2 >Backups</h2>
            </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>

          <v-col cols="6">

            <v-row class="pt-1">
              <v-col>
                <p class="hidden-sm-and-down">Succeeded</p>
                <v-icon color="orange lighten-1">mdi-check-bold</v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h2 class="font-weight-bold">{{ getAzureData.backupCount.succeeded }}</h2>
              </v-col>
            </v-row>

          </v-col>

          <v-col cols="6">

            <v-row class="pt-1">
              <v-col>
                <p class="hidden-sm-and-down">Failed</p>
                <v-icon color="orange lighten-1">mdi-close-thick</v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h2 class="font-weight-bold">{{ getAzureData.backupCount.failed }}</h2>
              </v-col>
            </v-row>

          </v-col>

        </v-row>

        <v-row>
          <v-col cols="10 offset-1">
            <v-card tile flat :class="getAzureData.appCount.failed == 0 ? 'green lighten-4' : 'red lighten-3'">
              <v-card-text>
              <h2 >Apps</h2>
            </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>

          <v-col cols="6">

            <v-row class="pt-1">
              <v-col>
                <p class="hidden-sm-and-down">Running</p>
                <v-icon color="orange lighten-1">mdi-check-bold</v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h2 class="font-weight-bold">{{ getAzureData.appCount.running }}</h2>
              </v-col>
            </v-row>

          </v-col>

          <v-col cols="6">

            <v-row class="pt-1">
              <v-col>
                <p class="hidden-sm-and-down">Failed</p>
                <v-icon color="orange lighten-1">mdi-close-thick</v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h2 class="font-weight-bold">{{ getAzureData.appCount.failed }}</h2>
              </v-col>
            </v-row>

          </v-col>

        </v-row>

      </v-card-text>
    </v-card>

  </div>
</template>

<!-- SCRIPT -->
<script>

// IMPORTS
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  name: 'HomeAzure',

  // COMPUTED
  computed: {
    ...mapGetters([
      'getAzureData',
      'getShowAzureData'
    ]),
    ...mapActions([
      'fetchAzureData'
    ])
  },

  // CREATED
  created () {
    this.$store.dispatch('fetchAzureData')
  }
})

</script>
