





















/* eslint-disable */

// IMPORTS
import Vue from 'vue'
import { mapGetters} from 'vuex'
import FailedHeader from './AzureFailedHeader.vue'
import FailedBackups from './AzureFailedBackups.vue'
import FailedApps from './AzureFailedApps.vue'

export default Vue.extend({
  name: 'AzureFailed',

  // COMPONENTS
  components: {
    FailedHeader,
    FailedBackups,
    FailedApps
  },

  // COMPUTED
  computed: {
    ...mapGetters([
      'getShowAzureData'
    ])
  }
}

)

