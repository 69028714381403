


















// IMPORTS
import Vue from 'vue'
import Navigation from './components/Navigation.vue'

export default Vue.extend({
  name: 'App',

  // COMPONENTS
  components: {
    Navigation
  }

})

