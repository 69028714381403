export default {
  // LOGIN
  isLoginPage: true,

  // USER
  user: {},

  // CARROUSEL
  carrouselToggle: false,
  carrouselNextPage: '',

  // PAGE
  pageTitle: '',
  currentPage: '',
  refreshToggle: false,

  // NAVDRAWER
  navDrawerItems:
  [
    { icon: 'mdi-home', text: 'Home', route: '/home' },
    { icon: 'mdi-ticket', text: 'Zendesk', route: '/zendesk' },
    { icon: 'mdi-microsoft', text: 'Azure', route: '/azure' },
    { icon: 'mdi-slack', text: 'Slack', route: '/slack' },
    { icon: 'mdi-clock-time-three', text: 'Twinfield', route: '/twinfield' }
  ],

  // ZENDESK
  zendeskStatus: ['open', 'pending', 'hold'],
  countPending: '-',
  countHold: '-',
  countOpen: '-',
  countPrio: '-',
  tickets: [],
  showZendeskData: false,
  ticketLoader:
  {
    loaderToggle: false,
    loaderCount: 0
  },

  // Azure
  azureData: {
    backupCount: {
      succeeded: '-',
      failed: '-'
    },
    appCount: {
      running: '-',
      failed: '-'
    },
    resourceGroups: {}
  },
  showAzureData: false,
  azureLoader:
  {
    loaderToggle: false,
    loaderCount: 0
  },

  // SLACK
  slackMessages: {},
  synergyChannelId: 'CEMEBGN64',
  slackLoader:
  {
    loaderToggle: false,
    loaderCount: 0
  },

  // TWINFIELD
  twinfieldSubject: ['hoursbooked', 'week', 'month'],
  twinfieldDataShame: {},
  twinfieldDataWeek: {},
  twinfieldDataMonth: {},
  showTwinfieldData: false,
  twinfieldLoader:
  {
    loaderToggle: false,
    loaderCount: 0
  }
}
