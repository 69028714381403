import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  // DATA
  data: () => ({
    refreshInterval: ''
  }),

  // COMPUTED
  computed: {
    ...mapGetters([
      'getPageTitle'
    ])
  },

  // MOUNTED
  mounted () {
    // START REFRESH INTERVAL
    this.refreshInterval = setInterval(() => {
      // console.log(this.getPageTitle, 'data is refreshed at ' + new Date())
      this.$store.state.refreshToggle = !this.$store.state.refreshToggle
    }, 60000)
  },

  // BEFOREDESTROY
  beforeDestroy () {
    // REMOVE REFRESH INTERVAL
    clearInterval(this.refreshInterval)
  }

})
