// IMPORTS
import Home from '../views/Home.vue'
import Zendesk from '../views/Zendesk.vue'
import Slack from '../views/Slack.vue'
import UseInfo from '../views/UseInfo.vue'
import Azure from '../views/Azure.vue'
import Login from '../views/Login.vue'
import Twinfield from '../views/Twinfield.vue'

// ROUTES
const routes = [

  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guest: true
    }
  },

  {
    path: '/use',
    name: 'UseInfo',
    component: UseInfo,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/zendesk',
    name: 'Zendesk',
    component: Zendesk,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/azure',
    name: 'Azure',
    component: Azure,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/slack',
    name: 'Slack',
    component: Slack,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/twinfield',
    name: 'Twinfield',
    component: Twinfield,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/logout',
    name: 'Logout',
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/',
    redirect: '/login'
  },

  {
    path: '*',
    redirect: '/home'
  }

]

export default routes
