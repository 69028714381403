<!-- HTML -->
<template>
  <div id="slack">
    <v-container>

      <!-- PAGETITLE -->
      <PageTitle/>

      <!-- MESSAGES -->
      <Messages :key="getRefreshToggle"/>

    </v-container>
  </div>
</template>

<!-- SCRIPT -->
<script>

// IMPORTS
import Vue from 'vue'
import PageTitle from '../components/pages/PageTitle.vue'
import Messages from '../components/pages/slack/SlackMessages.vue'
import { mapGetters } from 'vuex'
import refreshMixin from '../mixins/refreshDataMixin'
import carrouselMixin from '../mixins/CarrouselMixin'

export default Vue.extend({
  name: 'Slack',

  // COMPONENTS
  components: {
    PageTitle,
    Messages
  },

  // MIXINS
  mixins: [refreshMixin, carrouselMixin],

  // COMPUTED
  computed: {
    ...mapGetters([
      'getRefreshToggle'
    ])
  },

  // CREATED
  created () {
    this.$store.state.pageTitle = 'Slack'
    this.$store.state.currentPage = 'Slack'
    this.$store.state.isLoginPage = false
    this.$store.state.carrouselNextPage = 'twinfield'
  }
})

</script>
