// IMPORTS
import axios from 'axios'

// BASEUrlS
const baseUrlUser = '/api/v1/user'
const baseUrlZendesk = '/api/v1/zendesk'
const baseUrlAzure = '/api/v1/azure'
const baseUrlSlack = '/api/v1/slack'
const baseUrlTwinfield = '/api/v1/twinfield'

export default {
  // USER DATA
  fetchUserData: ({ commit }: any) => {
    axios.get(baseUrlUser)
      .then(res => {
        commit('setUserData', res.data)
        // console.log('Action: user data:', res.data)
      })
  },

  // ZENDESK DATA
  fetchZendeskData: ({ commit }: any, status: string) => {
    axios.get(baseUrlZendesk + '?status=' + status)
      .then(res => {
        if (status === 'pending') {
          commit('setPendingCount', res.data.count)
        }
        if (status === 'hold') {
          commit('setHoldCount', res.data.count)
        }
        if (status === 'open') {
          commit('setOpenCount', res.data.count)
          commit('setPrioCount', res.data.highPrioCount)
          commit('setTickets', res.data.tickets)
        }
        // console.log('Action: Zendesk data - status: ', status, res.data)
      })
  },

  // AZURE DATA
  fetchAzureData: ({ commit }: any) => {
    axios.get(baseUrlAzure)
      .then(res => {
        commit('setAzureData', res.data)
        // console.log('Action: Azure data: ', res.data)
      })
  },

  // SLACK DATA
  fetchSlackMessages: ({ commit }: any, channelId: string) => {
    axios.get(baseUrlSlack + '?channelid=' + channelId)
      .then(res => {
        commit('setSlackMessages', res.data.messages)
        // console.log('Action: Slack messages: ', res.data.messages)
      })
  },

  // TWINFIELD DATA
  fetchTwinfieldData: ({ commit }: any, subject: string) => {
    axios.get(baseUrlTwinfield + '?subject=' + subject)
      .then(res => {
        if (subject === 'hoursbooked') {
          commit('setTwinfieldDataShame', res.data, subject)
        }
        if (subject === 'week') {
          commit('setTwinfieldDataWeek', res.data, subject)
        }
        if (subject === 'month') {
          commit('setTwinfieldDataMonth', res.data, subject)
        }
        // console.log('Action: Twinfield data - subject: ', subject, res.data)
      })
  }
}
