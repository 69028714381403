




































// IMPORTS
import Vue from 'vue'

export default Vue.extend({
  name: 'Ticketindicators',

  // PROPS
  props: ['ticket']

})
