











































// IMPORTS
import Vue from 'vue'

export default Vue.extend({
  name: 'Login',

  // COMPONENTS
  components: {
  },

  // METHODS
  methods: {
    login: function () {
      window.location.href = '/google-login'
    }
  },

  // CREATED
  created () {
    this.$store.state.pageTitle = 'Inloggen'
    this.$store.state.currentPage = 'Inloggen'
    this.$store.state.isLoginPage = true
  }

})

