















































// IMPORTS
import Vue from 'vue'
import PageTitle from '../components/pages/PageTitle.vue'

export default Vue.extend({
  name: 'Home',

  // COMPONENTS
  components: {
    PageTitle
  },

  // CREATED
  created () {
    this.$store.state.pageTitle = 'Handleiding'
    this.$store.state.currentPage = 'Handleiding'
    this.$store.state.isLoginPage = false
  }
})
