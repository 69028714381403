






































// IMPORTS
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'TwinfieldHeaderTotal',

  // DATA
  data: () => ({

  }),

  // COMPUTED
  computed: {
    ...mapGetters([
      ''
    ])
  },

  // METHODS
  methods: {

  }

  // // MOUNTED
  // mounted () {

  // },

  // // CREATED
  // created () {

  // }
})
