






































































// IMPORTS
import Vue from 'vue'
import IndicatorsPopUp from './IndicatorsPopUp.vue'

export default Vue.extend({
  name: 'Ticketsheader',

  // COMPONENTS
  components: {
    IndicatorsPopUp
  },

  // DATA
  data: () => ({
    dialog: false
  })
})
