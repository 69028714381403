import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({

  // DATA
  data: () => ({
    carrouselTimer: ''
  }),

  // COMPUTED
  computed: {
    ...mapGetters([
      'getCarrouselNextPage',
      'getCarrouselToggle'
    ])
  },

  // METHODS
  methods: {
    CarrouselStartTimer () {
      // console.log('Start carrousel page timer')
      this.carrouselTimer = setTimeout(this.GoToNextPage, 6000)
    },
    GoToNextPage () {
      this.$router.replace(this.getCarrouselNextPage)
    }
  },

  // CREATED
  created () {
    // CARROUSEL
    // IF CARROUSEL IS TURNED ON BEFORE ENTERING PAGE -> START TIMER
    if (this.getCarrouselToggle === true) {
      this.CarrouselStartTimer()
    }

    // WATCH IF CARROUSEL IS SWITCHED ON OR OFF
    this.unwatch = this.$store.watch(
      (state) => {
        return this.$store.state.carrouselToggle
      },
      (newValue, oldValue) => {
        // IF CARROUSEL IS SWITCHED ON -> START TIMER
        if (newValue === true) {
          this.CarrouselStartTimer()
        }
        // IF CARROUSEL IS SWITCHED OFF -> STOP TIMER
        if (newValue === false) {
          clearTimeout(this.carrouselTimer)
          // console.log('Stop carrousel timer')
        }
      },
      {
        deep: true
      }
    )
  },

  // BEFOREDESTROY
  beforeDestroy () {
    // STOP WATCHING CARROUSEL TOGGLE
    this.unwatch()
    // STOP CARROUSEL TIMER
    clearTimeout(this.carrouselTimer)
  }

})
