










































































// IMPORTS
import Vue from 'vue'
import { mapGetters } from 'vuex'
import TicketsHeader from './TicketsHeader.vue'
import TicketIndicators from './TicketsIndicators.vue'

export default Vue.extend({
  name: 'Tickets',

  // COMPONENTS
  components: {
    TicketsHeader,
    TicketIndicators
  },

  // DATA
  data: () => ({
    ZendeskURL: 'https://xuntos.zendesk.com/agent/tickets/'
  }),

  // COMPUTED
  computed: {
    ...mapGetters([
      'getTickets'
    ])
  }

})

