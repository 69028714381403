




































































































































// IMPORTS
import Vue from 'vue'

export default Vue.extend({
  name: 'IndicatorsPopUp',

  // DATA
  data: () => ({
    dialog: false
  })

})

