export default {

  // USER
  setUserData: (state: any, payload: any) => { state.user = payload },

  // ZENDESK - PENDING TICKET COUNT
  setPendingCount: (state: any, payload: any) => { state.countPending = payload },

  // ZENDESK - HOLD TICKET COUNT
  setHoldCount: (state: any, payload: any) => { state.countHold = payload },

  // ZENDESK - OPEN TICKET COUNT
  setOpenCount: (state: any, payload: any) => { state.countOpen = payload },

  // ZENDESK - OPEN TICKET PRIO COUNT
  setPrioCount: (state: any, payload: any) => { state.countPrio = payload },

  // ZENDESK - OPEN TICKETS
  setTickets: (state: any, payload: any) => {
    state.tickets = payload
    state.ticketLoader.loaderToggle = false
    state.ticketLoader.loaderCount = 1
    state.showZendeskData = true
  },

  // AZURE - DATA
  setAzureData: (state: any, payload: any) => {
    state.azureData = payload
    state.azureLoader.loaderToggle = false
    state.azureLoader.loaderCount = 1
    state.showAzureData = true
  },

  // SLACK - MESSAGES
  setSlackMessages: (state: any, payload: any) => {
    state.slackMessages = payload
    state.slackLoader.loaderToggle = false
    state.slackLoader.loaderCount = 1
  },

  // TWINFIELD DATA - SHAME
  setTwinfieldDataShame: (state: any, payload: any) => {
    state.twinfieldDataShame = payload
    state.showTwinfieldData = true
  },

  // TWINFIELD DATA - WEEK
  setTwinfieldDataWeek: (state: any, payload: any) => { state.twinfieldDataWeek = payload },

  // TWINFIELD DATA - MONTH
  setTwinfieldDataMonth: (state: any, payload: any) => {
    state.twinfieldDataMonth = payload
    state.twinfieldLoader.loaderToggle = false
    state.twinfieldLoader.loaderCount = 1
    state.showTwinfieldData = true
  }

}
