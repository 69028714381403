<!-- HTML -->
<template>
  <div id="navigation">

    <!-- NAVIGATION BAR -->
    <v-app-bar dark app clipped-left color="grey darken-4" class="justify-center">

      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="!getIsLoginPage" aria-label="Menu" plain></v-app-bar-nav-icon>

      <!-- TITLE -->
      <v-toolbar-title class="text-uppercase">
        <a @click="GoToHomepage" class="homepagebutton">
        <span class="font-weight-light">Xuntos </span>
        <span>Dashboard</span>
        </a>
      </v-toolbar-title>

      <!-- CARROUSEL TOGGLE -->
      <div v-if="!getIsLoginPage" class="hidden-md-and-down">
        <v-btn v-if="carrouselOn === false" plain @click="ChangeCarrouselToggle" class="ml-3">
          <v-icon>mdi-play</v-icon>
        </v-btn>

        <v-btn v-if="carrouselOn === true" plain @click="ChangeCarrouselToggle" class="ml-3">
          <v-icon>mdi-pause</v-icon>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <!-- LINKS -->
      <div v-if="!getIsLoginPage" class="hidden-xs-only text-uppercase">

        <!-- WELKOM USER -->
        <span>Welkom {{ getUserData.firstName}}</span>

        <!-- SWAGGER -->
        <!-- <v-btn text href="/swagger" target="_blank" plain class=" hidden-md-and-down">
          <span >Swagger</span>
          <v-icon right> mdi-open-in-new</v-icon>
        </v-btn> -->

        <!-- LOGOUT -->
        <v-btn v-if="true" text href="/logout" plain>
          <span >Uitloggen</span>
          <v-icon right >mdi-logout</v-icon>
        </v-btn>

      </div>

    </v-app-bar>

    <!-- NAVIGATION DRAWER -->
    <v-navigation-drawer app clipped v-model="drawer" color="orange lighten-1" v-if="!getIsLoginPage">
      <v-list>
        <v-list-item v-for="item in getNavDrawerItems" :key="item.text" router :to="item.route" class="itemhover">

          <!-- ICON -->
          <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>

          <!-- TEXT -->
          <v-list-item-content>
            <v-list-item-title>{{item.text}}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>

      <!-- UITLOGGEN -->
      <div class="hidden-sm-and-up" >
      <v-divider class="mt-4"></v-divider>
        <v-list>
          <v-list-item>

            <!-- ICON -->
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>

            <!-- TEXT -->
            <v-list-item-content>
              <v-list-item-title>{{ getUserData.firstName}}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>

          <v-list-item router :to="'/logout'" class="itemhover" v-if="true">

            <!-- ICON -->
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>

            <!-- TEXT -->
            <v-list-item-content>
              <v-list-item-title>Uitloggen</v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<!-- SCRIPT -->
<script>

// IMPORTS
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  name: 'Navigation',

  // DATA
  data: () => ({
    drawer: null,
    carrouselOn: false
  }),

  // COMPUTED
  computed: {
    ...mapGetters([
      'getNavDrawerItems',
      'getIsLoginPage',
      'getUserData',
      'getCarrouselToggle'
    ]),
    ...mapActions([
      'fetchUserData'
    ])
  },

  // METHODS
  methods: {
    ChangeCarrouselToggle () {
      this.$cookies.set('CarrouselOn', !this.$store.state.carrouselToggle, Infinity)
      this.$store.state.carrouselToggle = !this.$store.state.carrouselToggle
      this.carrouselOn = !this.carrouselOn
    },
    GoToHomepage () {
      if (this.$router.currentRoute.name !== 'Home') {
        this.$router.replace('/home')
      }
    }
  },

  // CREATED
  created () {
    this.$store.dispatch('fetchUserData')

    // IF COOKIE DOESN'T EXIST OR IS FALSE -> SET CREATE COOKIE AND SET ALL TOGGLES TO FALSE
    if (this.$cookies.isKey('CarrouselOn') === false || this.$cookies.get('CarrouselOn') === 'false') {
      this.$cookies.set('CarrouselOn', 'false', Infinity)
      this.$store.state.carrouselToggle = false
      this.carrouselOn = false
    // IF COOKIE IS TRUE -> SET ALL TOGGLES TO TRUE
    } else {
      this.$store.state.carrouselToggle = true
      this.carrouselOn = true
    }
  }

})

</script>

<!-- STYLE -->
<style scoped>
.itemhover:hover {background-color: #FFCA7A;}

#navigation .homepagebutton {
  text-decoration: none;
  color: white;

}
</style>
