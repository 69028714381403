





































































/* eslint-disable */

// IMPORTS
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import TicketLoader from './Tickets/TicketLoader.vue'

export default Vue.extend({
  name: 'ZendeskNumbers',

  // COMPONENTS
  components: {
    TicketLoader
  },

  // COMPUTED
  computed: {
    ...mapGetters([
      'getTicketLoader',
      'getCountPending',
      'getCountOpen',
      'getCountHold',
      'getZendeskStatus',
      'getShowZendeskData'
    ]),
    ...mapActions([
      'fetchZendeskData'
    ])
  },

  // CREATED
  created () {
    for (status in this.getZendeskStatus) {
      status = this.getZendeskStatus[status]
      this.$store.dispatch('fetchZendeskData', status)
    }

    if (this.getTicketLoader.loaderCount === 0) {
      this.$store.state.ticketLoader.loaderToggle = true
    }
  }
}

)

