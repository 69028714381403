// IMPORTS
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import axios from 'axios'

// VUE
Vue.use(VueRouter)

// ROUTER
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    axios.get('/api/v1/user')
      .then(function (res: any) {
        if (res.data.email === null) {
          // console.log('requiresauth -> Login', res.data.email)
          next({ name: 'Login', query: { returnUrl: to.fullPath } })
        } else {
          // console.log('requiresauth -> page', res.data.email, res.data)
          next()
        }
      })
  } else {
    axios.get('/api/v1/user')
      .then(function (res: any) {
        if (res.data.email === null) {
          // console.log('guest -> Login', res.data.email)
          next()
        } else {
          // console.log('guest -> home', res.data.email)
          next({ name: 'Home' })
        }
      })
  }
})

export default router
