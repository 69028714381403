<!-- HTML -->
<template>
  <div id="twinfield">
    <v-container>

      <!-- LOADER -->
      <Loader/>

      <!-- PAGETITLE -->
      <PageTitle/>

      <!-- SHAME -->
      <Shame :key="getRefreshToggle"/>

      <!-- WEEK & MONTH -->
      <WeekAndMonth/>

    </v-container>
  </div>
</template>

<!-- SCRIPT -->
<script>

// IMPORTS
import Vue from 'vue'
import PageTitle from '../components/pages/PageTitle.vue'
import CarrouselMixin from '@/mixins/CarrouselMixin'
import refreshDataMixin from '@/mixins/refreshDataMixin'
import { mapGetters } from 'vuex'
import Shame from '../components/pages/twinfield/TwinfieldShame.vue'
import WeekAndMonth from '../components/pages/twinfield/TwinfieldWeekAndMonth.vue'
import Loader from '../components/pages/twinfield/TwinfieldDataLoader.vue'

export default Vue.extend({
  name: 'Twinfield',

  // COMPONENTS
  components: {
    PageTitle,
    Shame,
    WeekAndMonth,
    Loader
  },

  // MIXINS
  mixins: [refreshDataMixin, CarrouselMixin],

  // COMPUTED
  computed: {
    ...mapGetters([
      'getRefreshToggle',
      'getTwinfieldLoader'
    ])
  },

  // CREATED
  created () {
    this.$store.state.pageTitle = 'Twinfield'
    this.$store.state.currentPage = 'Twinfield'
    this.$store.state.isLoginPage = false
    this.$store.state.carrouselNextPage = 'home'

    if (this.getTwinfieldLoader.loaderCount === 0) {
      this.$store.state.twinfieldLoader.loaderToggle = true
    }
  }
})
</script>
