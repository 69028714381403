







































// IMPORTS
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'TicketDialog',

  // DATA
  data: () => ({
    oopsDiagram: false
  }),

  // COMPUTED
  computed: {
    ...mapGetters([
      'getTicketLoader',
      'getTickets'
    ])
  },

  // METHODS
  methods: {
    CheckIfDataIsLoaded () {
      if (Object.keys(this.getTickets).length === 0) {
        this.getTicketLoader.loaderToggle = false
        this.oopsDiagram = true
        // console.log('An error occured while loading Zendesk data')
      }
    },
    TryAgain () {
      this.getTicketLoader.loaderCount = 0
      this.$store.state.zendeskRefreshToggle = !this.$store.state.zendeskRefreshToggle
    }
  },

  // MOUNTED
  mounted () {
    if (this.getTicketLoader.loaderToggle === true) {
      setTimeout(() => {
        this.CheckIfDataIsLoaded()
      }, 8000)
    }
  }

})
