































// IMPORTS
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Week from './TwinfieldWeek.vue'
import Month from './TwinfieldMonth.vue'

export default Vue.extend({
  name: 'TwinfieldWeekAndMonth',

  // COMPONENTS
  components: {
    Week,
    Month
  },

  // DATA
  data: () => ({

  }),

  // COMPUTED
  computed: {
    ...mapGetters([
      ''
    ])
  },

  // METHODS
  methods: {

  }

  // // MOUNTED
  // mounted () {

  // },

  // // CREATED
  // created () {

  // }
})
