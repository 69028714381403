






























// IMPORTS
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import ShameHeader from './TwinfieldShameHeader.vue'

export default Vue.extend({
  name: 'TwinfieldShame',

  // COMPONENTS
  components: {
    ShameHeader
  },

  // DATA
  data: () => ({

  }),

  // COMPUTED
  computed: {
    ...mapGetters([
      'getTwinfieldDataShame',
      'getShowTwinfieldData'
    ]),
    ...mapActions([
      'fetchTwinfieldData'
    ])
  },

  // METHODS
  methods: {

  },

  // // MOUNTED
  // mounted () {

  // },

  // CREATED
  created () {
    this.$store.dispatch('fetchTwinfieldData', 'hoursbooked')
  }
})
