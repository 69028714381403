<!-- HTML -->
<template>
  <div id="hometwinfield">

    <!-- TITLE -->
    <v-row class="mb-1" no-gutters>
      <v-col cols="12">
        <v-card tile aria-label="Paginatitel">
          <h1 v-if="!getShowTwinfieldData" class="text-center font-weight-medium">Shame on you!</h1>
          <h1 v-if="getShowTwinfieldData" class="text-center font-weight-medium" :class="wanBoekers == 0 ? 'green lighten-4' : 'red lighten-3'">Shame on you!</h1>
        </v-card>
      </v-col>
    </v-row>

    <!-- NUMBERS -->
    <v-card tile flat class="pb-3 mb-4">
      <v-card flat loading="orange lighten-1">
        <v-card-text v-if="!getShowTwinfieldData">
          Wanboekers ophalen
        </v-card-text>
      </v-card>
      <v-card-text v-if="getShowTwinfieldData">
        <v-row class="justify-center">
          <div v-for="collega in getTwinfieldDataShame.persons" :key="collega.name">
            <div v-if="collega.name != 'Gijs Maris' && collega.name != 'Joost' && collega.name != 'Wael Desouky' && collega.name != 'Ruud van der Linden' && collega.allHoursBooked == false">
              <v-col>
                <v-card tile flat class="text-center">
                  <v-img :src="collega.profileImage" height="70" width="70" class="avatar" :title=collega.name></v-img>
                  {{ collega.firstName }}
                </v-card>
              </v-col>
            </div>
          </div>
        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>

<!-- SCRIPT -->
<script>

// IMPORTS
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  name: 'HomeTwinfield',

  data: () => ({
    wanBoekers: 1,
    tempCounter: 0,
    collega: {}
  }),

  // COMPUTED
  computed: {
    ...mapGetters([
      'getTwinfieldDataShame',
      'getShowTwinfieldData'
    ]),
    ...mapActions([
      'fetchTwinfielDdata'
    ])
  },

  // METHODS
  methods: {
    CountPersons () {
      for (this.collega in this.getTwinfieldDataShame.persons) {
        this.collega = this.getTwinfieldDataShame.persons[this.collega]
        if (this.collega.allHoursBooked === false && this.collega.name !== 'Wael Desouky' && this.collega.name !== 'Gijs Maris' && this.collega.name !== 'Joost' && this.collega.name !== 'Ruud van der Linden') {
          this.tempCounter = this.tempCounter + 1
        }
        this.wanBoekers = this.tempCounter
      }
    }
  },

  // CREATED
  created () {
    this.$store.dispatch('fetchTwinfieldData', 'hoursbooked')

    // WATCH IF SHAME DATA IS CHANGED
    this.unwatch = this.$store.watch(
      (state) => {
        return this.$store.state.twinfieldDataShame
      },
      (newValue, oldValue) => {
        // IF SHAME DATA IS ADDED
        if (Object.keys(newValue).length !== 0) {
          this.CountPersons()
        }
      }
    )
  },

  beforeDestroy () {
    // STOP WATCHING SHAME DATA
    this.unwatch()
  }
})

</script>

<style scoped>
#hometwinfield .avatar{
    /* width: 50%; */
    border-radius: 50%;
    border: calc-rem(3);
  }
</style>
