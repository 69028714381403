



















































































// IMPORTS
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import HeaderTotal from './TwinfieldHeaderTotal.vue'
// import HeaderPerson from './TwinfieldHeaderPerson.vue'

export default Vue.extend({
  name: 'TwinfieldWeek',

  // COMPONENTS
  components: {
    HeaderTotal
    // HeaderPerson
  },

  // DATA
  data: () => ({

  }),

  // COMPUTED
  computed: {
    ...mapGetters([
      'getTwinfieldDataWeek',
      'getShowTwinfieldData'
    ]),
    ...mapActions([
      'fetchTwinfieldData'
    ])
  },

  // METHODS
  methods: {

  },

  // // MOUNTED
  // mounted () {

  // },

  // CREATED
  created () {
    this.$store.dispatch('fetchTwinfieldData', 'week')
  }
})
