import state from './state'

export default {
  // LOGIN
  getIsLoginPage: (state: any) => { return state.isLoginPage },

  // USER
  getUserData: (state: any) => { return state.user },

  // CARROUSEL - TOGGLE
  getCarrouselToggle: (state: any) => { return state.carrouselToggle },

  // CARROUSEL - NEXT PAGE
  getCarrouselNextPage: (state: any) => { return state.carrouselNextPage },

  // PAGETITLE - PAGE TITLE
  getPageTitle: (state: any) => { return state.pageTitle },

  // REFRESH TOGGLE
  getRefreshToggle: (state: any) => { return state.refreshToggle },

  // NAVDRAWER - NAV DRAWER ITEMS
  getNavDrawerItems: (state: any) => { return state.navDrawerItems },

  // ZENDESK - TICKET LOADER
  getTicketLoader: (state: any) => { return state.ticketLoader },

  // ZENDESK - TICKET STATUS
  getZendeskStatus: (status: any) => { return state.zendeskStatus },

  // ZENDESK - TOGGLE SHOW DATA
  getShowZendeskData: (state: any) => { return state.showZendeskData },

  // ZENDESK - OPEN TICKETS
  getTickets: (state: any) => { return state.tickets },

  // ZENDESK - OPEN TICKET COUNT
  getCountOpen: (state: any) => { return state.countOpen },

  // ZENDESK - OPEN TICKET PRIO COUNT
  getCountPrio: (state: any) => { return state.countPrio },

  // ZENDESK - PENDING TICKET COUNT
  getCountPending: (state: any) => { return state.countPending },

  // ZENDESK - HOLD TICKET COUNT
  getCountHold: (state: any) => { return state.countHold },

  // Azure - TOGGLE SHOW DATA
  getShowAzureData: (state: any) => { return state.showAzureData },

  // AZURE - DATA LOADER
  getAzureLoader: (state: any) => { return state.azureLoader },

  // AZURE DATA
  getAzureData: (state: any) => { return state.azureData },

  // SLACK - DATA LOADER
  getSlackLoader: (state: any) => { return state.slackLoader },

  // SLACK - MESSAGES
  getSlackMessages: (state: any) => { return state.slackMessages },

  // TWINFIELD - DATA LOADER
  getTwinfieldLoader: (state: any) => { return state.twinfieldLoader },

  // TWINFIELD - SHOW DATA TOGGLE
  getShowTwinfieldData: (state: any) => { return state.showTwinfieldData },

  // TWINFIELD - SHAME DATA
  getTwinfieldDataShame: (state: any) => { return state.twinfieldDataShame },

  // TWINFIELD - WEEK DATA
  getTwinfieldDataWeek: (state: any) => { return state.twinfieldDataWeek },

  // TWINFIELD - MONTH DATA
  getTwinfieldDataMonth: (state: any) => { return state.twinfieldDataMonth }
}
