


























































































/* eslint-disable */

// IMPORTS
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DataLoader from './AzureDataLoader.vue'
import NumbersHeader from './AzureNumbersHeader.vue'

export default Vue.extend({
  name: 'AzureNumbers',

  // COMPONENTS
  components: {
    DataLoader,
    NumbersHeader
  },

  // COMPUTED
  computed: {
    ...mapGetters([
      'getAzureLoader',
      'getAzureData',
      'getShowAzureData'
    ]),
    ...mapActions([
      'fetchAzureData'
    ])
  },

  // CREATED
  created () {
      this.$store.dispatch('fetchAzureData')


    if (this.getAzureLoader.loaderCount === 0) {
      this.$store.state.azureLoader.loaderToggle = true
    }
  }
}

)

