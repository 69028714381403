







































// IMPORTS
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'SlackDataLoader',

  // DATA
  data: () => ({
    oopsDiagram: false
  }),

  // COMPUTED
  computed: {
    ...mapGetters([
      'getSlackLoader',
      'getSlackMessages'
    ])
  },

  // METHODS
  methods: {
    CheckIfDataIsLoaded () {
      if (Object.keys(this.getSlackMessages).length === 0) {
        this.getSlackLoader.loaderToggle = false
        this.oopsDiagram = true
        // console.log('An error occured while loading Slack data')
      }
    },
    TryAgain () {
      this.getSlackLoader.loaderCount = 0
      this.$store.state.slackRefreshToggle = !this.$store.state.slackRefreshToggle
    }
  },

  // MOUNTED
  mounted () {
    if (this.getSlackLoader.loaderToggle === true) {
      setTimeout(() => {
        this.CheckIfDataIsLoaded()
      }, 5000)
    }
  }
})
