<!-- HTML -->
<template>
  <div id="homezendesk">

    <!-- TITLE -->
    <v-row class="mb-1" no-gutters>
      <v-col cols="12">
        <v-card tile aria-label="Paginatitel">
          <h1 v-if="!getShowZendeskData" class="text-center font-weight-medium">Zendesk</h1>
          <h1 v-if="getShowZendeskData" class="text-center font-weight-medium" :class="getCountPrio == 0 ? 'green lighten-4' : 'red lighten-3'">Zendesk</h1>
        </v-card>
      </v-col>
    </v-row>

    <!-- NUMBERS -->
    <v-card tile flat class="pb-3 mb-4">
      <v-card flat loading="orange lighten-1">
        <v-card-text v-if="!getShowZendeskData">
          Tickets ophalen
        </v-card-text>
      </v-card>
      <v-card-text v-if="getShowZendeskData">
        <v-row>

          <v-col :class="getCountPrio == 0 ? 'col-12' : 'col-6'">

            <v-row class="pt-1">
              <v-col>
                <p class="hidden-sm-and-down">Open tickets</p>
                <v-icon color="orange lighten-1">mdi-email-open</v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h2 class="font-weight-bold">{{ getCountOpen }}</h2>
              </v-col>
            </v-row>

          </v-col>

          <v-col cols="6" :hidden="getCountPrio == 0 ? true : false">

            <v-row class="pt-1">
              <v-col>
                <p class="hidden-sm-and-down">Prio tickets</p>
                <v-icon color="orange lighten-1">mdi-alert-circle-outline</v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h2 class="font-weight-bold">{{ getCountPrio }}</h2>
              </v-col>
            </v-row>

          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>

<!-- SCRIPT -->
<script>

// IMPORTS
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  name: 'HomeZendesk',

  // DATA
  data: () => ({
    status: 'open'
    // prioCount: 0
  }),

  // COMPUTED
  computed: {
    ...mapGetters([
      'getCountOpen',
      'getCountPrio',
      'getShowZendeskData'
    ]),
    ...mapActions([
      'fetchZendeskData'
    ])
  },

  // CREATED
  created () {
    this.$store.dispatch('fetchZendeskData', this.status)
  }
})

</script>
